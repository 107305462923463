import React, { useEffect } from "react";
import {
  IoEye,
  IoSync,
  IoStatsChart,
  IoCheckmarkCircle,
  IoTimerOutline,
  IoPaperPlane,
} from "react-icons/io5";
import styled from "styled-components";
import { useModal } from "../components/useModal";

import { SignupForm } from "../components/forms";
import { Container, Wrapper, Row, Box, Button } from "../components/util";
import {
  CustomerQuote,
  Feature,
  ProductPricing,
  Modal,
} from "../components/site";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Image from "../components/image";

import DroppahLogo from "../images/logos/Droppah/Droppah_AProductByFlexiTime.svg";
import DroppahLogo_White from "../images/logos/Droppah/Droppah_Full_White.svg";
import DroppahPlusASB from "../images/logos/Other/DroppahPlusASB.svg";

const Or = styled.div`
  padding: 0 0;
  position: relative;
  user-select: none;

  div {
    color: #ccc;
    padding: 0 20px;
    z-index: 1;
    position: relative;
    display: table;
    margin: 0 auto;
  }

  hr {
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #ddd;
    border: none;
    top: 51%;
    transform: translateY(-50%);
    width: calc(50% - 20px);

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }
`;
const Asb = (props) => {
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  useEffect(() => {
    if (typeof document !== "undefined" && typeof window !== "undefined") {
      window.localStorage.setItem("utm_term", "ASBHUB");
    }
  }, []);

  return (
    <Layout>
      <Seo
        title="Droppah + ASB BusinessHub"
        description="Droppah has teamed up with ASB’s BusinessHub to support New Zealand businesses."
        pathname={props.location.pathname}
      />
      <Container className="primary">
        <Wrapper>
          <Box stackGap={40} className="-textCenter">
            <img
              src={DroppahPlusASB}
              className="-addShadow -center"
              alt="Droppah + ASB"
              height="100"
            />
            <Box stackGap={5}>
              <h1 className="h2">
                Droppah has teamed up with ASB’s BusinessHub to support New
                Zealand businesses
              </h1>
              <h3 className="-fontNormal">
                Build the perfect roster every time with Droppah
              </h3>
            </Box>
            <Button to="/asb#signup" className="-lg -center secondary">
              Get Rostering Software Now
            </Button>
          </Box>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <Box stackGap={80}>
            <Box stackGap={50}>
              <img
                className="-center"
                height="40"
                src={DroppahLogo_White}
                alt="Droppah Rosters"
              />
              <h2 className="-center -textCenter" css={{ maxWidth: "850px" }}>
                Leave the hard work to Droppah and build optimised staff rosters
                with ease
              </h2>
            </Box>
            <Image
              filename="DroppahHero_Full.png"
              alt="Droppah"
              maxWidth={850}
              centerImage
              addShadow
            />
          </Box>
          <hr />
          <Box stackGap={80}>
            <Row stackGap={80}>
              <Box size={50}>
                <Feature
                  icon={<IoEye key={0} />}
                  title="Stay one step ahead with real-time attendance"
                  description="Monitor the day’s attendance & breaks in real-time. Keep a tab on who’s in, who’s on break, who’s late and who’s working overtime."
                  style="droppah"
                />
              </Box>
              <Box size={50}>
                <Feature
                  icon={<IoCheckmarkCircle key={0} />}
                  title="Build your ideal employee roster with a single click"
                  description="Take advantage of Droppah’s AI feature to help build your ideal employee roster with skills, availability, shift preferences and wage costs factored in. Plus, copy and paste last week’s roster if it is good to go."
                  style="droppah"
                />
              </Box>
            </Row>
            <Row stackGap={80}>
              <Box size={50}>
                <Feature
                  icon={<IoSync key={0} />}
                  title="Sync with PayHero"
                  description="Sync employees, leave requests, teams and work with Droppah by connecting to a PayHero payroll account. Approve & send time segments recorded in Droppah straight to PayHero, ready for your next pay run."
                  style="droppah"
                />
              </Box>
              <Box size={50}>
                <Feature
                  icon={<IoStatsChart key={0} />}
                  title="Control costs & optimise coverage"
                  description="Set your staffing budget and stick to it. Limit unplanned overtime by applying strict start, strict end or default break durations to shifts."
                  style="droppah"
                />
              </Box>
            </Row>
            <Row stackGap={80}>
              <Box size={50}>
                <Feature
                  icon={<IoTimerOutline key={0} />}
                  title="Accurately record time worked"
                  description="Manage time & attendance easily with Droppah's in-built photo time clock feature. Admin users can view historical clock records and photos to ensure staff are being paid correctly."
                  style="droppah"
                />
              </Box>
              <Box size={50}>
                <Feature
                  icon={<IoPaperPlane key={0} />}
                  title="Publish rosters & notify employees"
                  description="Make communication easy by emailing published shifts to your employees and download them to print out."
                  style="droppah"
                />
              </Box>
            </Row>
          </Box>
          <hr />
          <Box stackGap={60}>
            <div className="-textCenter">
              <h2>Droppah Pricing</h2>
              <h4>
                Free for up to 10 Employees · Easy Set Up · No Credit Card
                Required
              </h4>
            </div>
            <ProductPricing
              selectedProduct="droppah"
              ctaLink="/asb#signup"
              hideAddOns
            />
          </Box>
        </Wrapper>
      </Container>
      <Container id="signup">
        <Wrapper stackGap={80} maxWidth={800}>
          <Box stackGap={40}>
            <Box stackGap={30} className="-textCenter">
              <img
                className="-center"
                height="30"
                src={DroppahLogo_White}
                alt="Droppah Rosters"
              />
              <Box stackGap={10}>
                <h2 className="-textCenter">
                  Take Droppah for a test run and receive your first two months
                  free
                </h2>
                <h4>
                  Streamline your workforce management with Droppah rosters. ASB
                  customers get the first two months of their Droppah
                  subscription for free. Simply use promo code{" "}
                  <span
                    css={{
                      padding: "1px 4px",
                      border: "solid 1px #ddd",
                      borderRadius: "5px",
                      fontWeight: "500",
                    }}
                  >
                    ASBHUB
                  </span>{" "}
                  when subscribing to Droppah.
                </h4>
              </Box>
            </Box>
            <Box className="-center" stackGap={40} css={{ maxWidth: "520px" }}>
              <SignupForm submitText="Let's Go" candidateSignup={false} hideFormExtras center />
              {/* <Or>
                <hr className="left" />
                <hr className="right" />
                <div>Or</div>
              </Or>
              <Button className="white -lg -full" onClick={toggleModal} atag>
                Book a Sales Call
              </Button> */}
              <p
                className="-textCenter"
                css={{ opacity: "0.6", marginTop: "30px !important" }}
              >
                * This promotional offer is available for ASB Business customers
                creating a new subscription to Droppah, and can’t be used with
                any other offer on the same subscription. The two month free
                period begins on the date the subscription is purchased and is
                available for up to a maximum of $100 per month.
              </p>
              <p
                className="-textCenter"
                css={{ opacity: "0.4", marginTop: "30px !important" }}
              >
                © FlexiTime Ltd. {new Date().getFullYear()}
              </p>
            </Box>
          </Box>
        </Wrapper>
      </Container>
      <Modal
        isActive={itemModalOpen}
        embedURL="https://calendly.com/droppah-sales/sales-call"
        embedTitle="Book a Sales Call | Droppah Sales"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default Asb;
